<template>
  <div>
    <el-descriptions title="项目实施前期成本总结" direction="vertical" :column="4" border>
      <el-descriptions-item
        label="投标报名费"
        label-class-name="labelClassName"
        contentClassName="contentClassName"
        >{{ options.biddingEnrollmentFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="劳务费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ options.laborFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="打印装订费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ options.printingAndBindingFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="交通费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ options.transportationFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="中标服务费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ options.bidServiceFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="餐费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ options.mealFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="CA介质新办及续期费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ options.caMediaNewAndRenewalFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item label="住宿费">{{
        options.accommodationFee | thousands
      }}</el-descriptions-item>
      <el-descriptions-item
        label="快递费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ options.expressage | thousands }}</el-descriptions-item
      >
      <el-descriptions-item label="投标总费用">{{
        options.totalBidExpense | thousands
      }}</el-descriptions-item>
      <el-descriptions-item label="其他">{{ options.other | thousands }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: 'CostTotal',
  components: {},
  props: {
    //页面数据
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  filters: {
    thousands(a) {
      if (!a) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
/deep/.labelClassName,
/deep/.contentClassName {
  width: 25%;
}
</style>
